<template>
    <b-form>
        <h5 class="emp-paragraph" v-if="SITE_DETAILS.employment_title">
            {{ SITE_DETAILS.employment_title }}
        </h5>
        <p class="emp-paragraph" v-if="SITE_DETAILS.employment_paragraph">
            {{ SITE_DETAILS.employment_paragraph }}
        </p>
        <b-container class="p-0">
            <b-row>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-input type="text" v-validate="'required'" id="first_name" name="first_name" class="jd-input"
                                 :placeholder="$t('message.first_name')"
                                 v-model="employment.first_name"></b-input>
                        <span v-if="veeErrors.has('first_name')" class="text-danger jd-text-12">
              {{ veeErrors.first('first_name') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-input type="text" v-validate="'required'" name="last_name" id="last_name" class="jd-input"
                                 :placeholder="$t('message.last_name')"
                                 v-model="employment.last_name"></b-input>
                        <span v-if="veeErrors.has('last_name')" class="text-danger jd-text-12">
               {{ veeErrors.first('last_name') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-input type="email" v-validate="'required|email'" name="email" id="email" class="jd-input"
                                 :placeholder="$t('message.email')"
                                 v-model="employment.email"></b-input>
                        <span v-if="veeErrors.has('email')" class="text-danger jd-text-12">
              {{ veeErrors.first('email') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-input type="text" id="phone" name="phone" v-validate="{required : true, max: 14, min: 14}"
                                 class="jd-input" :placeholder="$t('message.phone')"
                                 maxlength="14" @input="acceptNumber"
                                 v-model="employment.phone"></b-input>
                        <span v-if="veeErrors.has('phone')" class="text-danger jd-text-12">
              {{ veeErrors.first('phone') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-input type="text" id="city" name="city" v-model="employment.city"
                                 class="jd-input" :placeholder="$t('message.city')"></b-input>
                        <span v-if="veeErrors.has('city')" class="text-danger jd-text-12">
              {{ veeErrors.first('city') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-4">
                        <b-form-group class="position-relative mb-0">
                            <i class="fas fa-calendar-alt position-absolute calendar-icon mt-1"></i>
                            <datepicker data-vv-name="date" :placeholder="$t('message.date_of_birth')" format="MMM dd yyyy"
                                        :disabled-dates=disabledFn
                                        class="hover-show-tooltip is-invalid"
                                        v-model="employment.date_of_birth"
                                        :input-class="` jd-input form-control pr-0 bg-input w-100 pl-4 pt-1 ${veeErrors.has('employment.date_of_birth') ? 'is-invalid' : ''}`">
                            </datepicker>
                            <span v-if="veeErrors.has('employment.date_of_birth')"
                                  class="text-danger jd-text-10 position-absolute">
                            {{ veeErrors.first('employment.date_of_birth') }}
                        </span>
                        </b-form-group>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-4 position-relative">
                        <b-form-select type="text"
                                       :options="[
                               { text: $t('message.employment_type'), value: null},
                               { text: $t('message.full_time'), value: $t('message.full_time')},
                               { text: $t('message.part_time'), value: $t('message.part_time')},
                               { text: $t('message.seasonal'), value: $t('message.seasonal')},
                           ]"
                                       :class="`${veeErrors.has('employment_type') ? 'is-invalid' : ''} hover-show-tooltip jd-input radius-0`"
                                       v-model="employment.employment_type"
                                       name="employment_type"></b-form-select>
                        <span v-if="veeErrors.has('employment_type')" class="text-danger jd-text-10 position-absolute">
              {{ veeErrors.first('employment_type') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-input type="text" id="languages" name="languages" class="jd-input"
                                 v-model="employment.languages"
                                 :placeholder="$t('message.languages')"></b-input>
                        <span v-if="veeErrors.has('languages')" class="text-danger jd-text-12">
              {{ veeErrors.first('languages') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="12">
                    <b-form-group class="mb-4 position-relative">
                        <b-form-select type="text"
                                       :options="[
                               { text: $t('message.education_level'), value: null},
                               { text: $t('message.middle_school'), value: $t('message.middle_school')},
                               { text: $t('message.high_school'), value: $t('message.high_school')},
                               { text: $t('message.ged'), value: $t('message.ged')},
                               { text: $t('message.some_college'), value: $t('message.some_college')},
                               { text: $t('message.two_degree'), value: $t('message.two_degree')},
                               { text: $t('message.four_degree'), value: $t('message.four_degree')},
                           ]"
                                       :class="`${veeErrors.has('education_level') ? 'is-invalid' : ''} hover-show-tooltip jd-input radius-0`"
                                       v-model="employment.education_level"
                                       name="education_level"></b-form-select>
                        <span v-if="veeErrors.has('education_level')" class="text-danger jd-text-10 position-absolute">
                  {{ veeErrors.first('education_level') }}
                </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-textarea class="resize-none jd-input" rows="6" name="last_job" id="last_job"
                                    :placeholder="$t('message.last_job')"
                                    v-model="employment.last_job"></b-textarea>
                        <span v-if="veeErrors.has('last_job')" class="text-danger jd-text-12">
              {{ veeErrors.first('last_job') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-textarea class="resize-none jd-input" rows="6" name="work_experience"
                                    id="work_experience"
                                    v-model="employment.work_experience"
                                    :placeholder="$t('message.work_experience')"></b-textarea>
                        <span v-if="veeErrors.has('work_experience')" class="text-danger jd-text-12">
              {{ veeErrors.first('work_experience') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-input type="number" id="hourly_rate" name="hourly_rate" class="jd-input"
                                 v-model="employment.hourly_rate" :placeholder="$t('message.hourly_rate')"></b-input>
                        <span v-if="veeErrors.has('hourly_rate')" class="text-danger jd-text-12">
              {{ veeErrors.first('hourly_rate') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-textarea class="resize-none jd-input" rows="6" name="tell_yourself"
                                    id="tell_yourself"
                                    v-model="employment.tell_yourself"
                                    :placeholder="$t('message.tell_yourself')"></b-textarea>
                        <span v-if="veeErrors.has('tell_yourself')" class="text-danger jd-text-12">
              {{ veeErrors.first('tell_yourself') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="12">
                    <b-form-group class="mb-3">
                        <b-input type="text" id="about_us" name="about_us" class="jd-input"
                                 v-model="employment.about_us" :placeholder="$t('message.about_us')"></b-input>
                        <span v-if="veeErrors.has('about_us')" class="text-danger jd-text-12">
              {{ veeErrors.first('about_us') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.hard_work_one') }}
                            <br>
                            {{ $t('message.hard_work_two') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.hard_work" :aria-describedby="ariaDescribedby"
                                      name="hard_work" value="1">
                            {{ $t('message.yes') }}
                        </b-form-radio>
                        <b-form-radio v-model="employment.hard_work" :aria-describedby="ariaDescribedby"
                                      name="hard_work" value="0">
                            {{ $t('message.no') }}
                        </b-form-radio>
                        <span v-if="veeErrors.has('hard_work')" class="text-danger jd-text-12">
              {{ veeErrors.first('hard_work') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.experience_customers') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.experience_customers" :aria-describedby="ariaDescribedby"
                                      name="experience_customers" value="1">{{ $t('message.yes') }}
                        </b-form-radio>
                        <b-form-radio v-model="employment.experience_customers" :aria-describedby="ariaDescribedby"
                                      name="experience_customers" value="0">{{ $t('message.no') }}
                        </b-form-radio>
                        <span v-if="veeErrors.has('experience_customers')" class="text-danger jd-text-12">
              {{ veeErrors.first('experience_customers') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="12">
                    <p class="jd-font-bold color-dark mb-1">
                        {{ $t('message.references') }}
                    </p>
                    <p>{{ $t('message.references_list') }}</p>
                </b-col>
                <b-col lg="12">
                    <b-form-group class="mb-3">
                        <b-textarea class="resize-none jd-input" rows="6" name="reference_one" id="reference_one"
                                    v-model="employment.reference_one"
                                    :placeholder="$t('message.reference_one')"></b-textarea>
                        <span v-if="veeErrors.has('reference_one')" class="text-danger jd-text-12">
              {{ veeErrors.first('reference_one') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-5">
                        <b-textarea class="resize-none jd-input" rows="6" name="reference_two" id="reference_two"
                                    v-model="employment.reference_two"
                                    :placeholder="$t('message.reference_two')"></b-textarea>
                        <span v-if="veeErrors.has('reference_two')" class="text-danger jd-text-12">
              {{ veeErrors.first('reference_two') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-5">
                        <b-textarea class="resize-none jd-input" rows="6" name="reference_three" id="reference_three"
                                    v-model="employment.reference_three"
                                    :placeholder="$t('message.reference_three')"></b-textarea>
                        <span v-if="veeErrors.has('reference_three')" class="text-danger jd-text-12">
              {{ veeErrors.first('reference_three') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.convicted_felony') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.convicted_felony" :aria-describedby="ariaDescribedby"
                                      name="convicted_felony" value="1">{{ $t('message.yes') }}
                        </b-form-radio>
                        <b-form-radio v-model="employment.convicted_felony" :aria-describedby="ariaDescribedby"
                                      name="convicted_felony" value="0">{{ $t('message.no') }}
                        </b-form-radio>
                        <span v-if="veeErrors.has('convicted_felony')" class="text-danger jd-text-12">
              {{ veeErrors.first('convicted_felony') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-textarea class="resize-none jd-input" rows="6" name="felony_description"
                                    id="felony_description"
                                    v-model="employment.felony_description"
                                    :placeholder="$t('message.felony_description')"></b-textarea>
                        <span v-if="veeErrors.has('felony_description')" class="text-danger jd-text-12">
              {{ veeErrors.first('felony_description') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.driver_license')}}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.driver_license" :aria-describedby="ariaDescribedby"
                                      name="driver_license" value="1">{{ $t('message.yes')}}
                        </b-form-radio>
                        <b-form-radio v-model="employment.driver_license" :aria-describedby="ariaDescribedby"
                                      name="driver_license" value="0">{{ $t('message.no')}}
                        </b-form-radio>
                        <span v-if="veeErrors.has('driver_license')" class="text-danger jd-text-12">
              {{ veeErrors.first('driver_license') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3">
                        <b-textarea class="resize-none jd-input" rows="6" name="medical_conditions"
                                    id="medical_conditions"
                                    v-model="employment.medical_conditions"
                                    :placeholder="$t('message.medical_conditions')">
                        </b-textarea>
                        <span v-if="veeErrors.has('medical_conditions')" class="text-danger jd-text-12">
              {{ veeErrors.first('medical_conditions') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.suspended_driver_license') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.suspended_driver_license" :aria-describedby="ariaDescribedby"
                                      name="suspended_driver_license" value="1">{{ $t('message.yes') }}
                        </b-form-radio>
                        <b-form-radio v-model="employment.suspended_driver_license" :aria-describedby="ariaDescribedby"
                                      name="suspended_driver_license" value="0">{{ $t('message.no') }}
                        </b-form-radio>
                        <span v-if="veeErrors.has('suspended_driver_license')" class="text-danger jd-text-12">
              {{ veeErrors.first('suspended_driver_license') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.nationwide_check') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.nationwide_check" :aria-describedby="ariaDescribedby"
                                      name="nationwide_check" value="1">{{ $t('message.yes') }}
                        </b-form-radio>
                        <b-form-radio v-model="employment.nationwide_check" :aria-describedby="ariaDescribedby"
                                      name="nationwide_check" value="0">{{ $t('message.no') }}
                        </b-form-radio>
                        <span v-if="veeErrors.has('nationwide_check')" class="text-danger jd-text-12">
              {{ veeErrors.first('nationwide_check') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.drug_test') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.drug_test" :aria-describedby="ariaDescribedby"
                                      name="drug_test" value="1">{{ $t('message.yes') }}
                        </b-form-radio>
                        <b-form-radio v-model="employment.drug_test" :aria-describedby="ariaDescribedby"
                                      name="drug_test" value="0">{{ $t('message.no') }}
                        </b-form-radio>
                        <span v-if="veeErrors.has('drug_test')" class="text-danger jd-text-12">
              {{ veeErrors.first('drug_test') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group class="mb-3" v-slot="{ ariaDescribedby }">
                        <label>
                            {{ $t('message.us_citizen') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-radio v-model="employment.us_citizen" :aria-describedby="ariaDescribedby"
                                      name="us_citizen" value="1">{{ $t('message.yes') }}
                        </b-form-radio>
                        <b-form-radio v-model="employment.us_citizen" :aria-describedby="ariaDescribedby"
                                      name="us_citizen" value="0">{{ $t('message.no') }}
                        </b-form-radio>
                        <span v-if="veeErrors.has('us_citizen')" class="text-danger jd-text-12">
              {{ veeErrors.first('us_citizen') }}
            </span>
                    </b-form-group>
                </b-col>
                <b-col lg="12">
                    <b-form-group class="mb-5">
                        <label class="jd-font-bold color-dark">
                            {{ $t('message.consent') }}
                            <span class="asterisk">*</span>
                        </label>
                        <b-form-checkbox
                                id="checkbox-1"
                                v-model="employment.consent"
                                name="consent"
                                v-validate="'required'"
                                value="1"
                                unchecked-value="0"
                        >
                            {{ $t('message.consent_description') }}
                        </b-form-checkbox>
                        <span v-if="veeErrors.has('consent')" class="text-danger jd-text-12">
              {{ veeErrors.first('consent') }}
            </span>
                    </b-form-group>
                </b-col>

                <b-col lg="12">
                    <b-form-group class="mb-3">
                        <vue-recaptcha
                                ref="recaptcha"
                                @verify="onVerify"
                                :loadRecaptchaScript="true"
                                sitekey="6LfKlXklAAAAAKpqx3-UZYZVnUp3MuiRDCtsfrj0">
                        </vue-recaptcha>
                        <span v-if="!recaptchaVerified && isSubmitted" class="text-danger jd-text-12">Please verify recaptcha.</span>
                    </b-form-group>
                </b-col>

                <b-col lg="6">
                    <b-btn variant="primary" block @click="submit" :disabled="loading" class="w-50">{{ $t('message.submit') }}</b-btn>
                </b-col>
            </b-row>
        </b-container>
    </b-form>
</template>

<script>
import {RepositoryFactory} from "@/api/RepositoryFactory";
import VueRecaptcha from 'vue-recaptcha';
import {mapGetters} from "vuex";
import Datepicker from "vuejs-datepicker";

export default {
    components: {VueRecaptcha, Datepicker},
    data() {
        return {
            employment: {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                city: null,
                employment_type: null,
                education_level: null,
                languages: null,
                last_job: null,
                work_experience: null,
                about_us: null,
                tell_yourself: null,
                hard_work: null,
                experience_customers: null,
                reference_one: null,
                reference_two: null,
                reference_three: null,
                convicted_felony: null,
                felony_description: null,
                driver_license: null,
                suspended_driver_license: null,
                nationwide_check: null,
                drug_test: null,
                us_citizen: null,
                consent: null,
                date_of_birth: null,
                hourly_rate: null,
                medical_conditions: null,
            },
            loading: false,
            recaptchaVerified: false,
            isSubmitted: false,
            disabledFn: {
                from: this.$moment().toDate()
            }
        }
    },
    watch: {
        "employment.phone": function (newVal) {
            if (newVal && newVal.length === 4) {
                this.employment.phone += ") ";
            }
        }
    },
    computed: {
        ...mapGetters(['SITE_DETAILS'])
    },
    methods: {
        acceptNumber() {
            let x = this.employment.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.employment.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        onVerify: function (response) {
            this.recaptchaVerified = true;
        },
        submit() {
            this.isSubmitted = true;
            if (!this.recaptchaVerified) {
                this.recaptchaVerified = false;
                return;
            }

            this.isSubmitted = false;
            this.$validator.validateAll().then((res) => {
                if (res) {
                    this.loading = true;
                    RepositoryFactory.get('site').employmentForm(this.employment)
                        .then((res) => {
                            this.$validator.reset();
                            this.veeErrors.clear();
                            this.$snotify.success("Successfully submitted, We will contact you soon.")
                            this.$router.push({name: 'thankyou'});
                        }).catch((err) => {
                        if (err.response.status === 422) {
                            let errors = err.response.data.errors;
                            this.$snotify.error("PLease fix bellow errors.");
                            Object.keys(errors).map((item) => this.veeErrors.add({
                                field: item,
                                msg: errors[item].join(',')
                            }));
                        } else {
                            this.$snotify.error("Oops, Something went wrong please try again later.");
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        }
    }
}
</script>


<style scoped lang="scss">

.emp-paragraph {
  color: #4f4f4f;
  margin-bottom: 20px;
}

.color-dark {
  line-height: 1.3;
  color: #4b4f58;
}

.radius-0 {
  border-radius: 0;
}

.form-group {
  label {
    .asterisk {
      color: #790000;
      margin-left: 4px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .resize-none {
    resize: none;
  }

  .custom-radio {
    ::v-deep .custom-control-label {
      cursor: pointer;
    }

    ::v-deep .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          font-weight: 700;
          color: #4f4f4f;
        }
      }
    }
  }
}

::v-deep(.vdp-datepicker__calendar) {
  header {
    .next {
      &.disabled {
        &:after {
          border: none !important;
        }
      }
    }
  }
}
</style>